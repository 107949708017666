@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap");
@import url('https://fonts.cdnfonts.com/css/allrounder-monument-test');

/* //////////////////////////////////////////////////////////////////////////// */
                                /* Body */
/* //////////////////////////////////////////////////////////////////////////// */

main {
    min-height: 100vh;
}


/* //////////////////////////////////////////////////////////////////////////// */
                                /* Navbar */
/* //////////////////////////////////////////////////////////////////////////// */



/* //////////////////////////////////////////////////////////////////////////// */
                                /* Home */
/* //////////////////////////////////////////////////////////////////////////// */



/* //////////////////////////////////////////////////////////////////////////// */
                                /* Projects */
/* //////////////////////////////////////////////////////////////////////////// */
.awssld__controls__arrow-left, 
.awssld__controls__arrow-right {
  --organic-arrow-color: #c46c25;
}

.awssld__bullets{
    --control-bullet-color: #5a351d;
    --control-bullet-active-color: #5a351d;      
}

.awssld__container {
    --slider-height-percentage: 90%;
  }

/* //////////////////////////////////////////////////////////////////////////// */
                                /* BeforeAfter */
/* //////////////////////////////////////////////////////////////////////////// */
.before-after-container .awssld__container {
  --slider-height-percentage: 63%;
}



/* //////////////////////////////////////////////////////////////////////////// */
                                /* Contact */
/* //////////////////////////////////////////////////////////////////////////// */




/* //////////////////////////////////////////////////////////////////////////// */
                                /* Footer */
/* //////////////////////////////////////////////////////////////////////////// */
footer {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    color: #fff;
    text-align: center;
    margin-top: 1rem;
}